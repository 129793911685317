import { template as template_be7ef0df568b4d9b9189a8bfd7551a4f } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import Issue from './issue';
export default class SchoolCodeError extends Component {
    @service
    intl;
    get messages() {
        return [
            this.intl.t('pages.school.not-found.sentence-1'),
            this.intl.t('pages.school.not-found.sentence-2')
        ];
    }
    static{
        template_be7ef0df568b4d9b9189a8bfd7551a4f(`
    <Issue @message={{this.messages}} @class="hint" @blobType="warning" @refreshAction={{@refreshAction}} />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
