import { template as template_1a48d22fcd2d4f6a84d9698c93340bdb } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { eq } from 'ember-truth-helpers';
export default class EmbeddedWebComponent extends Component {
    @action
    handleAnswer(event) {
        this.args.setAnswerValue(event.detail[0]);
    }
    static{
        template_1a48d22fcd2d4f6a84d9698c93340bdb(`
    {{#if (eq @tagName "qcu-image")}}
      <qcu-image props={{@props}} {{on "answer" this.handleAnswer}} data-testid={{@tagName}} />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
