import { template as template_fce04403d957440ca2b27566fe4f225b } from "@ember/template-compiler";
export default template_fce04403d957440ca2b27566fe4f225b(`
  <div class="card-wrapper">
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
