import { template as template_08dda46e109442c1896ac9b9e07e965c } from "@ember/template-compiler";
export default template_08dda46e109442c1896ac9b9e07e965c(`
  <svg width="230px" height="352px" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="100%" height="100%" rx="8" fill="#1A355B" />
    <g opacity="0.2">
      <path
        d="M191.69 79.5295C192.57 76.9295 211.43 82.8495 210.42 85.8495C208.68 91.0195 203.07 93.7995 197.9 92.0595C192.73 90.3195 189.95 84.7095 191.69 79.5395V79.5295Z"
        fill="white"
      />
      <path
        d="M47.6702 55.9994C46.5502 54.5494 56.8602 46.2694 58.1402 47.9494C60.3602 50.8394 59.8202 54.9894 56.9302 57.2094C54.0402 59.4294 49.8902 58.8894 47.6702 55.9994Z"
        fill="white"
      />
      <path
        d="M212.9 215.41C214.977 215.41 216.66 213.726 216.66 211.65C216.66 209.573 214.977 207.89 212.9 207.89C210.824 207.89 209.14 209.573 209.14 211.65C209.14 213.726 210.824 215.41 212.9 215.41Z"
        fill="white"
      />
      <path
        d="M59.4103 225.219C61.4869 225.219 63.1703 223.536 63.1703 221.459C63.1703 219.383 61.4869 217.699 59.4103 217.699C57.3337 217.699 55.6503 219.383 55.6503 221.459C55.6503 223.536 57.3337 225.219 59.4103 225.219Z"
        fill="white"
      />
      <path
        d="M36.1202 116.709C39.9255 116.709 43.0102 113.625 43.0102 109.819C43.0102 106.014 39.9255 102.929 36.1202 102.929C32.315 102.929 29.2302 106.014 29.2302 109.819C29.2302 113.625 32.315 116.709 36.1202 116.709Z"
        fill="white"
      />
      <path
        d="M64.8502 33.2795C67.0759 33.2795 68.8802 31.4752 68.8802 29.2495C68.8802 27.0238 67.0759 25.2195 64.8502 25.2195C62.6245 25.2195 60.8202 27.0238 60.8202 29.2495C60.8202 31.4752 62.6245 33.2795 64.8502 33.2795Z"
        fill="white"
      />
      <path
        d="M207.13 189.07C210.317 189.07 212.9 186.486 212.9 183.3C212.9 180.113 210.317 177.53 207.13 177.53C203.944 177.53 201.36 180.113 201.36 183.3C201.36 186.486 203.944 189.07 207.13 189.07Z"
        fill="white"
      />
      <path
        d="M93.2802 221.689C95.0702 221.209 97.2102 220.88 98.3002 222.39C99.4702 224 98.4802 229.859 98.4302 230.549C98.3802 231.189 98.4702 237.409 97.0602 238.689C95.9302 239.719 94.2402 239.199 92.8202 238.629L91.1102 237.939C86.6902 235.709 83.4502 232.03 83.6302 229.65C83.8002 227.41 87.2102 223.76 92.3302 221.95L93.2802 221.689Z"
        fill="white"
      />
      <path
        d="M101.4 46.2995C102.63 44.4395 104.3 42.4495 106.48 42.9395C108.81 43.4595 112.58 49.5295 113.08 50.1895C113.54 50.7995 118.57 56.3395 118.32 58.6095C118.12 60.4395 116.18 61.3095 114.45 61.9295L112.36 62.6695C106.61 64.1695 100.76 63.4395 99.0303 61.1495C97.4003 58.9995 97.5803 52.9995 100.74 47.2995L101.39 46.3195L101.4 46.2995Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3889_16481">
        <rect x="72.2402" y="132.649" width="105" height="56" rx="5.36563" fill="white" />
      </clipPath>
    </defs>
  </svg>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
